import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { DialogBuilder } from '../reusables/dialogBuilder'
import { SnackbarBuilder } from '../reusables/snackbarBuilder'
import { labelMap, postalCodeDialog } from './postalCodeConfigurationContent'
import { validatePostalCode } from '../../utils/validation'
import { formatCountryPostalCode } from '../../utils/string'
import {
  makeStyles,
  Paper,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  FormControl,
} from '@material-ui/core'
import { postPostalCode, deletePostalCode, setPostalCodeSnackBar } from './postalCodeConfiguration.redux'

const useStyles = makeStyles(theme => ({
  pageContent: {
    marginLeft: '10%',
    marginRight: '10%',
    padding: '2%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '1%',
      marginRight: '1%',
    },
  },
  table: {
    marginTop: '15px',
    borderStyle: 'groove',
    '& th': {
      fontWeight: 'bold',
      fontSize: '18px',
    },
    '& th:not(:last-child), & td:not(:last-child)': {
      borderRightStyle: 'groove',
    },
  },
}))

export const PostalCodeConfiguration = () => {
  const [postalCodeToAdd, setPostalCodeToAdd] = useState('')
  const [postalCodeToDelete, setPostalCodeToDelete] = useState('')
  const [isDisableAddButton, setIsDisableAddButton] = useState(true)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { postalCodeSnackBar } = useSelector(state => state.postalCodeConfig)
  const { selectedStore, language } = useSelector(state => state.auth)
  const [selectedStoreSnapshot, setSelectedStoreSnapshot] = useState(selectedStore)

  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    setSelectedStoreSnapshot(selectedStore)
  }, [selectedStore])
  const sameDayExcludedPostalCodes = selectedStoreSnapshot.sameDayExcludedPostalCodes || []

  return (
    <>
      <Paper className={classes.pageContent}>
        <h2>{labelMap.headers[selectedStoreSnapshot.country].pageTitle[language]}</h2>
        <form>
          <Grid container spacing={1} direction="row" alignItems="center">
            <Grid item>
              <FormControl className={classes.formControl}>
                <TextField
                  className={classes.input}
                  variant="outlined"
                  inputProps={{
                    maxLength: selectedStoreSnapshot.country === 'CA' ? 7 : 10,
                  }}
                  label={labelMap.fields.addPostalCode[selectedStoreSnapshot.country].label[language]}
                  value={formatCountryPostalCode(postalCodeToAdd, selectedStoreSnapshot.country)}
                  helperText={labelMap.fields.addPostalCode[selectedStoreSnapshot.country].helperText[language]}
                  type={'input'}
                  onChange={e => {
                    const postalCode = e.target.value.trim()
                    setPostalCodeToAdd(postalCode)
                    validatePostalCode(postalCode, selectedStoreSnapshot.country)
                      ? setIsDisableAddButton(false)
                      : setIsDisableAddButton(true)
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                className={classes.button}
                variant="contained"
                color="primary"
                disabled={isDisableAddButton}
                onClick={e => {
                  e.preventDefault()
                  dispatch(postPostalCode({ postalCode: postalCodeToAdd, storeId: selectedStoreSnapshot.id }))
                  setPostalCodeToAdd('')
                  setIsDisableAddButton(true)
                }}
              >
                {labelMap.buttons.add[language]}
              </Button>
            </Grid>
          </Grid>
        </form>
        <TableContainer className={classes.table}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{labelMap.headers[selectedStoreSnapshot.country].tableFirstColumn[language]}</TableCell>
                <TableCell>{labelMap.headers.tableSecondColumn[language]}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sameDayExcludedPostalCodes.map((postalCode, index) => (
                <TableRow key={index}>
                  <TableCell scope="row">
                    {formatCountryPostalCode(postalCode, selectedStoreSnapshot.country)}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setIsDialogOpen(true)
                        setPostalCodeToDelete(postalCode)
                      }}
                    >
                      {labelMap.buttons.delete[language]}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <DialogBuilder
        content={{
          text: postalCodeDialog.text[language],
          disagree: postalCodeDialog.disagree[language],
          agree: postalCodeDialog.agree[language],
        }}
        open={isDialogOpen}
        onDisagree={() => setIsDialogOpen(false)}
        onAgree={() => {
          dispatch(deletePostalCode({ postalCode: postalCodeToDelete, storeId: selectedStoreSnapshot.id }))
          setIsDialogOpen(false)
        }}
      />
      <SnackbarBuilder
        contentText={postalCodeSnackBar.message}
        isOpen={postalCodeSnackBar.show}
        handleClose={() => dispatch(setPostalCodeSnackBar({ ...postalCodeSnackBar, show: false, message: '' }))}
        severityLevel={postalCodeSnackBar.success ? 'success' : 'error'}
        location={{ vertical: 'top', horizontal: 'center' }}
      />
    </>
  )
}
